/**
 * Script custom for theme
 *
 * TABLE OF CONTENT
 *
 * Header: main menu
 * Header: main menu mobile
 * Sidebar: sticky sidebar
 * Feature: Preloading
 * Feature: Back to top
 * Custom select.
 */

(function ($) {
	"use strict";

	$(document).ready(function () {
		thim_startertheme.ready();
	});

	$(window).load(function () {
		thim_startertheme.load();
	});

	var thim_startertheme = {

		/**
		 * Call functions when document ready
		 */
		ready: function () {
			this.header_menu();
            this.header_menu_mobile();
			this.back_to_top();
			this.feature_preloading();
			this.custom_select_box();
			this.contactform7();
			this.thim_blog_auto_height();
			this.thim_add_class_grid_item();
            this.slide_slick_col_1();
            this.sticky_sidebar();
            this.input_num_product();
            this.custom_input_file();
            this.validate_form();
            this.thim_popup_video();
            this.magnific_popup_video();
            this.fillter_videos();
            this.select_filter_video();
		},

		/**
		 * Call functions when window load.
		 */
		load: function () {
            this.bp_grid_isotope();
			this.parallax();
			this.thim_post_gallery();
		},

		// CUSTOM FUNCTION IN BELOW
		thim_post_gallery: function () {
			$('article.format-gallery .flexslider').imagesLoaded(function () {
				$('.flexslider').flexslider({
					slideshow     : true,
					animation     : 'fade',
					pauseOnHover  : true,
					animationSpeed: 400,
					smoothHeight  : true,
					directionNav  : true,
					controlNav    : false
				});
			});
		},


        /**
         * Mobile menu
         */
        header_menu_mobile: function() {
            $(document).on('click', '.menu-mobile-effect', function(e) {
                e.stopPropagation();
                $('.responsive #wrapper-container').addClass('mobile-menu-open');
            });

            $(document).on('click', '.mobile-menu-open', function() {
                $('.responsive #wrapper-container.mobile-menu-open').
                removeClass('mobile-menu-open');
            });

            $('.responsive .mobile-menu-container .menu li.menu-item-has-children >a').after('<span class="icon-toggle"><i class="fa fa-angle-down"></i></span>');
            $('.mobile-menu-container .widget_nav_menu .menu-useful-links-container .menu>li.menu-item-has-children >a').after('<span class="icon-toggle"><i class="fa fa-angle-down"></i></span>');

            $('.responsive .mobile-menu-container .menu li.menu-item-has-children .icon-toggle, .mobile-menu-container .widget_nav_menu .menu-useful-links-container .menu>li.menu-item-has-children .icon-toggle').
            on('click', function() {
                if ($(this).next('ul.sub-menu').is(':hidden')) {
                    $(this).next('ul.sub-menu').slideDown(200, 'linear');
                    $(this).html('<i class="fa fa-angle-up"></i>');
                } else {
                    $(this).next('ul.sub-menu').slideUp(200, 'linear');
                    $(this).html('<i class="fa fa-angle-down"></i>');
                }
            });

            $('.mobile-menu-container').on('click', function(e) {
                e.stopPropagation();
            })
        },

        /**
         * Header menu sticky, scroll, v.v.
         */
        header_menu: function() {
            var off_Top = ($('#wrapper-container').length > 0) ? $('#wrapper-container').offset().top : 0;
            var dentalTop = off_Top;
            if($(window).outerWidth() <= 600) {
                dentalTop = 0;
            }
            var $topbar = $('#toolbar');
            var $header = $('.sticky-header');
            var $elementToStick = $header.find('.element-to-stick');
            var latestScroll = 0;

            if ($('#toolbar').length) {
                $('.header-overlay').css({
                    top: $('#toolbar').outerHeight() + off_Top + 'px',
                });
            }
            else {
                $('.header-overlay').css({
                    top: off_Top + 'px',
                });
            }

            $elementToStick.css('top', dentalTop + 'px');

            var startFixed = $elementToStick.offset().top - dentalTop;

            $(window).scroll(function() {
                var current = $(this).scrollTop();

                if (current > startFixed) {
                    $header.css('height', $header.outerHeight() + 'px');

                    $header.addClass('fixed');
                } else {
                    $header.removeClass('fixed');
                    $header.css('height', 'auto');
                }

                if (current > latestScroll && current > $elementToStick.outerHeight() + startFixed) {
                    if (!$header.hasClass('menu-hidden')) {
                        $header.addClass('menu-hidden');
                        $elementToStick.css({
                            top: - $elementToStick.outerHeight(),
                        });
                    }
                } else {
                    if ($header.hasClass('menu-hidden')) {
                        $header.removeClass('menu-hidden');
                        $elementToStick.css({
                            top: dentalTop,
                        });
                    }
                }

                latestScroll = current;
            });
        },

		/**
		 * Back to top
		 */
		back_to_top: function () {
			var $element = $('#back-to-top');
			$(window).scroll(function () {
				if ($(this).scrollTop() > 100) {
					$element.addClass('scrolldown').removeClass('scrollup');
				} else {
					$element.addClass('scrollup').removeClass('scrolldown');
				}
			});

			$element.on('click', function () {
				$('html,body').animate({scrollTop: '0px'}, 800);
				return false;
			});
		},

        /**
         * sticky_sidebar
         */
        sticky_sidebar: function() {
            try {
                var offsetTop = 10;
                var spacingTop = 10;

                if($('#wpadminbar').length) {
                    offsetTop += $('#wpadminbar').outerHeight();
                    spacingTop += $('#wpadminbar').outerHeight();
                }

                if($('.sticky-header .element-to-stick').length) {
                    offsetTop += $('.sticky-header .element-to-stick').outerHeight();
                }

                $('.sticky-sidebar').each(function () {
                    if ($(this).length > 0) {
                        if ( $().theiaStickySidebar ) {
                            $(this).theiaStickySidebar({
                                'typeSticky'            : 1,
                                'spacingTopDefault'     : spacingTop,
                                'containerSelector'     : '',
                                'additionalMarginTop'   : offsetTop,
                                'additionalMarginBottom': 10,
                                'updateSidebarHeight'   : false,
                                'minWidth'              : 992,
                                'sidebarBehavior'       : 'modern',
                            });
                        }
                    }
                });

            } catch(er) {console.log(er);}
        },

		/**
		 * Parallax init
		 */
		parallax: function () {
			$(window).stellar({
				horizontalOffset: 0,
				verticalOffset  : 0
			});
		},

		/**
		 * feature: Preloading
		 */
		feature_preloading: function () {
			var $preload = $('#thim-preloading');
			if ($preload.length > 0) {
				$preload.fadeOut(1000, function () {
					$preload.remove();
				});
			}
		},


		/**
		 * Custom select
		 */
		custom_select_box: function () {
            try {
                $('select').each(function() {
                    if(!$(this).parent().hasClass('wrap-select2')) {
                        $(this).parent().addClass('wrap-select2');
                    }
                });

                $(".wrap-select2").each(function(){
                    $(this).append('<span class="dropDownSelect2"></span>');

                    var select = $(this).children('select');
                    var style = $(this).data('style');
                    var dropdown = $(this).children('.dropDownSelect2');

                    $(select).select2({
                        minimumResultsForSearch: 20,
                        dropdownParent: dropdown,
                        theme: style,
                    });
                });

            } catch(er) {console.log(er);}
        },

        /**
         * Quantity product
         */
        input_num_product: function() {
            try {
                $(document).on("click",'.wrap-num-product .btn-num-down', function () {
                    var numProduct = Number($(this).parent().find('.num-product').val());
                    if(numProduct > 0) $(this).parent().find('.num-product').val(numProduct - 1);
                    $('.woocommerce-cart-form button[name="update_cart"]').removeAttr("disabled")
                });


                $(document).on("click",'.wrap-num-product .btn-num-up', function () {
                    var numProduct = Number($(this).parent().find('.num-product').val());
                    $(this).parent().find('.num-product').val(numProduct + 1);
                    $('.woocommerce-cart-form button[name="update_cart"]').removeAttr("disabled")
                });
            } catch(er) {console.log(er);}
        },



		/**
		 * Custom effect and UX for contact form.
		 */
		contactform7: function () {
			$(".wpcf7-submit").on('click', function () {
				$(this).css("opacity", 0.2);
				$(this).parents('.wpcf7-form').addClass('processing');
				$('input:not([type=submit]), textarea').attr('style', '');
			});

			$(document).on('spam.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('invalid.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('mailsent.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('mailfailed.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$('body').on('click', 'input:not([type=submit]).wpcf7-not-valid, textarea.wpcf7-not-valid', function () {
				$(this).removeClass('wpcf7-not-valid');
			});
		},


		/**
		 * Blog auto height
		 */
		thim_blog_auto_height: function () {
			var $blog = $('.blog .blog-content article, .archive .blog-content article'),
				maxHeight = 0,
				setH = true;

			// Get max height of all items.
			$blog.each(function () {
				setH = $(this).hasClass('column-1') ? false : true;
				if (maxHeight < $(this).find('.content-inner').height()) {
					maxHeight = $(this).find('.content-inner').height();
				}
			});

			// Set height for all items.
			if (maxHeight > 0 && setH) {
				$blog.each(function () {
					$(this).find('.content-inner').css('height', maxHeight);
				});
			}
		},

        /**
         * Magnific-Popup-Video
         */
        magnific_popup_video: function() {
            try {
                $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
                    disableOn: 700,
                    type: 'iframe',
                    mainClass: 'mfp-fade',
                    removalDelay: 160,
                    preloader: false,

                    fixedContentPos: false
                });
            } catch(er) {console.log(er);}
        },

        /**
         * Videos popup
         */
        thim_popup_video: function () {
            $('.videos-list .btn-play').magnificPopup({
                type: 'inline',
                fixedContentPos: false,
                removalDelay: 500, //delay removal by X to allow out-animation
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = this.st.el.attr('data-effect');
                        var id_video = this.st.el.attr('data-id'),
                            obj_html = this.st.el.attr('href');
                        $(obj_html).html('<i class="fa fa-spinner fa-spin"></i>');
                        $.ajax({
                            type: 'POST',
                            data: 'action=builderpress_open_video_popup_ajax&id_video=' + id_video,
                            url: ajaxurl,
                            success: function (res) {
                                $(obj_html).html(res);
                            },
                        });
                    },
                    close: function(){
                        $(this.st.el.attr('href')).html('');
                    },
                },
                midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
            });
        },

        /**
         * grid_isotope
         */
        bp_grid_isotope: function() {
            if ( $().isotope ) {
                $('.grid-isotope').isotope({
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.grid-item',
                    percentPosition: true,
                    masonry: {
                        // use element for option
                        columnWidth: '.grid-sizer'
                    }
                })
            }
        },

		/**
		*
		*
		* */
        thim_add_class_grid_item: function() {
        	$('.grid-isotope .grid-item').each(function(i) {
                if ( i === 0 ) {
                    $(this).addClass('feature-item');
                }
            });
		},

        /**
         * custom_input_file
         */
        custom_input_file: function() {
            try {
                $('.input-choose-file').each(function(){
                    var $inputField = $(this).find('.input-field input[type="file"]');
                    var $inputValue = $(this).find('.input-file-value');

                    $inputField.on('change', function(){
                        $inputValue.html($inputField.val());
                    })
                });

            } catch(er) {console.log(er);}
        },

        /**
         * validate_form
         */
        validate_form: function() {
            try {
                var input = $('.require input, .require textarea');

                $('.validate-form').on('submit',function(){
                    var check = true;

                    for(var i=0; i<input.length; i++) {
                        if(input[i].val().trim() === ''){
                            input[i].parent().addClass('alert-validate');
                            check = false;
                        }
                    }
                    return check;
                });


                $(input).each(function(){
                    $(this).on('focus',function(){
                        $(this).parent().removeClass('alert-validate');
                    });
                });

            } catch(er) {console.log(er);}
        },

        /**
         * Slide slick col.
         */
        slide_slick_col_1: function() {
            $('.js-call-slick-col-1').each(function(){
                var data =  [
                    ['responsive', 'array'],
                    ['customdot', 'bool'],
                    ['numofshow', 'number'],
                    ['numofscroll', 'number'],
                    ['fade', 'bool'],
                    ['loopslide', 'bool'],
                    ['autoscroll', 'bool'],
                    ['speedauto', 'number'],
                    ['verticalslide', 'bool'],
                    ['verticalswipe', 'bool'],
                    ['rtl', 'bool'],
                    ['navfor', 'string'],
                    ['animate', 'bool'],
                    ['middlearrow', 'string']
                ]

                var parameter = {
                    responsive: [[1, 1], [1, 1], [1, 1], [1, 1], [1, 1]],
                    customdot: false,
                    numofshow: 1,
                    numofscroll: 1,
                    fade: false,
                    loopslide: false,
                    autoscroll: false,
                    speedauto: 5000,
                    verticalslide: false,
                    verticalswipe: false,
                    rtl: false,
                    navfor: '',
                    animate: false,
                    middlearrow: null
                }

                var showDot = false;
                var showArrow = false;
                var wrapSlick = $(this);
                var slideSlick = $(this).find('.slide-slick');
                var itemSlick = $(slideSlick).find('.item-slick');
                var layerSlick = $(slideSlick).find('[data-appear]');
                var actionSlick = [];

                // Check show dot, arrows
                if($(wrapSlick).find('.wrap-dot-slick').length > 0) {
                    showDot = true;
                }

                if($(wrapSlick).find('.wrap-arrow-slick').length > 0) {
                    showArrow = true;
                }

                // Get data
                for(var i=0; i<data.length; i++) {
                    var value = $(this).data(data[i][0]);

                    if (value != null) {
                        if(data[i][1] === 'bool') {
                            if(value === '1' || value === 1) {
                                parameter[data[i][0]] = true;
                            } else {
                                parameter[data[i][0]] = false;
                            }
                        }
                        else if(data[i][1] === 'number') {
                            parameter[data[i][0]] = Number(value);
                        }
                        else if(data[i][1] === 'string') {
                            parameter[data[i][0]] = value;
                        }
                        else if(data[i][1] === 'array') {
                            var strArray = value.match(/(\d+)/g);
                            parameter[data[i][0]] = [
                                [Number(strArray[0]), Number(strArray[1])],
                                [Number(strArray[2]), Number(strArray[3])],
                                [Number(strArray[4]), Number(strArray[5])],
                                [Number(strArray[6]), Number(strArray[7])],
                                [Number(strArray[8]), Number(strArray[9])]
                            ]
                        }
                    }
                }

                // Call slick
                if(parameter.animate) {
                    $(layerSlick).addClass('animated').css('visibility', 'hidden');

                    $(slideSlick).on('init', function(){
                        showLayer(0);
                    });
                }

                $(slideSlick).slick({
                    asNavFor: parameter.navfor,
                    rtl: parameter.rtl,
                    vertical: parameter.verticalslide,
                    verticalSwiping: parameter.verticalswipe,
                    pauseOnFocus: false,
                    pauseOnHover: true,
                    slidesToShow: parameter.numofshow,
                    slidesToScroll: parameter.numofscroll,
                    fade: parameter.fade,
                    infinite: parameter.loopslide,
                    autoplay: parameter.autoscroll,
                    autoplaySpeed: parameter.speedauto,
                    arrows: showArrow,
                    appendArrows: $(wrapSlick).find('.wrap-arrow-slick'),
                    prevArrow: $(wrapSlick).find('.prev-slick'),
                    nextArrow: $(wrapSlick).find('.next-slick'),
                    dots: showDot,
                    appendDots: $(wrapSlick).find('.wrap-dot-slick'),
                    dotsClass:'dots-slick',
                    customPaging: function(slick, index) {
                        var portrait = $(slick.$slides[index]).data('thumb');

                        if(parameter.customdot) return '<img src=" ' + portrait + ' "/>';

                        return '<span></span>'
                    },
                    responsive: [
                        {
                            breakpoint: 1368,
                            settings: {
                                slidesToShow: parameter.responsive[0][0],
                                slidesToScroll: parameter.responsive[0][1]
                            }
                        },
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: parameter.responsive[1][0],
                                slidesToScroll: parameter.responsive[1][1]
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: parameter.responsive[2][0],
                                slidesToScroll: parameter.responsive[2][1]
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: parameter.responsive[3][0],
                                slidesToScroll: parameter.responsive[3][1]
                            }
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                slidesToShow: parameter.responsive[4][0],
                                slidesToScroll: parameter.responsive[4][1]
                            }
                        }
                    ]
                })
                    .on('setPosition', function(event, slick){
                        // Equal height
                        if($(this).parent().data('equalheight') === '1' || $(this).parent().data('equalheight') === 1) {
                            var maxHeight = 0;
                            var $items = $(this).find('.item-slick');

                            $items.each(function(){
                                if($(this).outerHeight() > maxHeight) {
                                    maxHeight = $(this).outerHeight();
                                }
                            })

                            $items.css('min-height', maxHeight);
                        }

                        // Middle Arrow
                        if(parameter.middlearrow != null) {
                            var $wrapArrows = $(wrapSlick).find('.wrap-arrow-slick');
                            var middleOf = $(wrapSlick).find(parameter.middlearrow).outerHeight();

                            $wrapArrows.css('height', middleOf + 'px');
                        }
                    });

                // Animate
                if(parameter.animate) {
                    $(slideSlick).on('afterChange', function(event, slick, currentSlide){
                        showLayer(currentSlide);
                    });
                }

                function showLayer(currentSlide) {
                    var layerCurrentItem = $(itemSlick[currentSlide]).find('[data-appear]');

                    for(var i=0; i<actionSlick.length; i++) {
                        clearTimeout(actionSlick[i]);
                    }

                    $(layerSlick).each(function(){
                        $(this).removeClass($(this).data('appear')).css('visibility', 'hidden');
                    })


                    for(var i=0; i<layerCurrentItem.length; i++) {
                        actionSlick[i] = setTimeout(function(index) {
                            $(layerCurrentItem[index]).addClass($(layerCurrentItem[index]).data('appear')).css('visibility', 'visible');
                        },$(layerCurrentItem[i]).data('delay'),i);
                    }
                };
            });
        },

        /**
         * fillter_full_container
         */
        fillter_videos: function () {
            try {
                $('.js-filter-full-container').each(function() {
                    var wrapFilter = $(this);
                    var toggle = $(this).find('.toggle-filter');
                    var content = $(this).find('.content-filter');

                    if($(wrapFilter).hasClass('active-filter')) {
                        $(content).show();
                    }
                    else {
                        $(content).hide();
                    }

                    $(toggle).on('click', function(e) {
                        e.preventDefault();
                        e.stopPropagation();

                        if($(wrapFilter).hasClass('active-filter')) {
                            $(wrapFilter).removeClass('active-filter');
                            $(content).slideUp('fast');
                        }
                        else {
                            $(wrapFilter).addClass('active-filter');
                            $(content).slideDown('fast');
                        }
                    })

                    $(content).on('click', function(e) {
                        e.stopPropagation();
                    })

                    $(window).on('click', function() {
                        $(wrapFilter).removeClass('active-filter');
                        $(content).slideUp('fast');
                    })
                });

            } catch(er) {console.log(er);}
        },

        /**
         * select filter mobile
         */
        select_filter_video: function () {
            try {
                $('.form_filter_mobile select').on('change', function () {
                    $('.form_filter_mobile').submit();
                });
            } catch(er) {console.log(er);}
        },
	};

})(jQuery);